body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section.section-tachyon ul > li {
  list-style: circle;
  margin-left: 2rem;
}

section.section-tachyon img {
  max-width: 95%;
  margin-bottom: 10px;
}

.main .desc {
  margin-bottom: 0px;
}

.textpage p {
  font-size: 2rem;
}

.textpage h2 {
  margin-top: 10px;
}

.section-benefits .desc {
  max-width: 80rem;
}
.section-benefits .desc p {
  padding: 1rem;
}

.chartPadding {
  width: 46rem;
  margin: 0px auto;
  max-width: 100%;
  margin-bottom: 5rem;
}

.chartPadding .card {
  margin: 0px auto;
  margin-bottom: 2rem;
  width: 40rem;
  max-width: 85%;
}

.types-of-species {
  margin-top: 5rem;
}

.species-title-alpha {
  background: no-repeat left/8% url('./../public/static/media/energyBall_yellow.png')
}

.species-title-elite {
  background: no-repeat left/8% url('./../public/static/media/energyBall_pink.png')
}

.species-title-sigma {
  background: no-repeat left/8% url('./../public/static/media/energyBall_blue.png')
}

.species-title-portal {
  background: no-repeat left/8% url('./../public/static/media/energyBall_purple.png')
}

.species-title-omega {
  background: no-repeat left/8% url('./../public/static/media/energyBall_red.png')
}

ul.species_types h2 {
  color: #fff;
  font-size: 18px;
  margin-top: 5px;
  padding-left: 4rem;
}

/*
.header-info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

@media (max-width: 991px)
.header-info {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.header-mmenu {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
*/
